/* eslint-disable react/style-prop-object */
import "@styles/App.scss";

import Editor from "@features/editor/Editor";
import { EditorHandler } from "@features/editor/EditorHandler";
import {
    BottomToolbarProps,
    DialogShow,
    LeftToolbarProps,
    RightToolbarProps,
    ToolbarItems,
    TopToolbarProps,
} from "@features/toolbar-item";
import styles from "@styles/index.module.scss";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const editorHandler = new EditorHandler();

function App() {
    // if (loading) {
    //   return <AppLoading />;
    // }

    useEffect(() => {
        const handleMessage = (event: any) => {
            if (event.origin !== "http://localhost:3000" && event.origin !== "https://mebb.cc") {
                return; // 确保消息来自可信任的源
            }
            console.log("Received message from parent:", event.data);

            // 发送消息回父窗口
            event.source.postMessage("Hello from iframe", event.origin);
        };

        window.addEventListener("message", handleMessage);

        // 清除事件监听器
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <div className={clsx("App", styles.app)}>
            <div className={styles.top}>
                <ToolbarItems {...TopToolbarProps} />
            </div>

            <div className={styles.right}>
                <ToolbarItems {...RightToolbarProps} />
            </div>

            <div className={styles.bottom}>
                <ToolbarItems {...BottomToolbarProps} />
            </div>

            <div className={styles.left}>
                <ToolbarItems {...LeftToolbarProps} />
            </div>

            <div className={styles.draw}>
                <Editor editorHandler={editorHandler} />
                {/* SVG sketchpad is placed here */}
            </div>

            <DialogShow editorHandler={editorHandler} />
        </div>
    );
}

export default App;
